<template>
  <MemberLayout>
    <div class="page">
      <div class="nav">
        <span style="margin-right: auto">{{
            t('notificationManagement')
          }}</span>
        <router-link
          :to="{ name: 'Notification.Create' }"
          class="btn btn-success"
        >
          <i class="pi pi-plus-circle"></i> {{ t('create.notification') }}
        </router-link>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-lg-8">
            <router-view :newNotification="state.currentNotification"/>
            <NotificationList/>
          </div>
          <div class="col-lg-4">
            <div class="notificationSimulation">
              <div class="notification">
                <div class="brandLogo">
                  <img
                    style="width: 50px; border-radius: 50%"
                    :src="state.currentNotification.iconUrl"
                  />
                </div>
                <div class="left">
                  <div class="title">
                    {{ state.currentNotification.title }}
                  </div>
                  <div class="message">
                    {{ state.currentNotification.content }}
                  </div>
                </div>
                <div class="right">
                  <div class="img">
                    <img :src="state.currentNotification.imageUrl"/>
                  </div>
                </div>
              </div>
            </div>
            <Simulation :scroll="false" :showcases="state.showcases"/>
          </div>
        </div>
      </div>
    </div>
  </MemberLayout>
</template>

<script>
import { onMounted, reactive } from 'vue'
import MemberLayout from './MemberLayout'
import useGlobal from '../composables/useGlobal'
import Simulation from '../components/HomePageManagement/Simulation'
import NotificationList from '../components/NotificationManagement/NotificationList'
import { useI18n } from 'vue-i18n'

export default {
  components: { NotificationList, Simulation, MemberLayout },

  setup() {
    const { t, emitter } = useGlobal()
    const { locale } = useI18n({ useScope: 'global' })

    const state = reactive({
      showcases: {},
      currentNotification: {},
      TRnewNotification: {
        title: 'Kampanyamız var!',
        content:
          'Tüm ürünlerimizdeki %20 indirimden yararlanın. Daha fazla bilgi için tıklayın.',
        targetUrl: '',
        iconUrl:
          'https://cdn.shopify.com/s/files/1/0526/0319/1484/products/macbook_600x600.jpg?v=1619434459',
        imageUrl:
          'https://cdn.shopify.com/s/files/1/0526/0319/1484/products/pexels-photo-6813470_600x600.jpg?v=1639552706',
      },
      ENnewNotification: {
        title: 'We have a campaign!',
        content:
          'Take advantage of 20% discount on all our products. Click for more information.',
        targetUrl: '',
        iconUrl:
          'https://cdn.shopify.com/s/files/1/0526/0319/1484/products/macbook_600x600.jpg?v=1619434459',
        imageUrl:
          'https://cdn.shopify.com/s/files/1/0526/0319/1484/products/pexels-photo-6813470_600x600.jpg?v=1639552706',
      },
    })

    emitter.on('notification-create-changed', (notification) => {
      state.currentNotification = notification
    })

    onMounted(() => {
      if (useI18n().locale.value === 'tr') {
        state.currentNotification = state.TRnewNotification
      }

      if (useI18n().locale.value === 'en') {
        state.currentNotification = state.ENnewNotification
      }
    })

    return {
      t,
      state,
      locale,
    }
  },
}
</script>

<style scoped>
.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5em;
}

.notificationSimulation {
  position: relative;
  display: flex;
  margin-left: -2em;
  justify-content: center;
}

.notification {
  background-color: #fff;
  border-radius: 10px;
  padding: 0.6em 1em;
  position: absolute;
  z-index: 1;
  margin: auto;
  margin-top: 3em;
  box-shadow: 2px 2px 0.5em rgba(0, 0, 0, 0.3);
  display: grid;
  grid-template-columns: auto auto 1fr;
  gap: 0.5em;
  align-items: center;
  width: 80%;
  animation: up 0.5s ease-in-out;
}

@keyframes up {
  0% {
    opacity: 0;
    transform: translateY(-200px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.notification .title {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 0.5em;
}

.notification .message {
  font-size: 0.9em;
  margin-bottom: 0.5em;
}

.notification .img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
}

.brandLogo {
  display: flex;
  height: 100%;
}

.brandLogo img {
  margin-bottom: auto;
}
</style>
