<template>
  <div class="card">
    <h5>{{ t('notificationList') }}</h5>
    <div v-if="isLoading" class="loading">
      <div class="spinner"></div>
    </div>
    <div v-else class="items mt-3">
      <div
        :class="{
          error: item.status === 'error',
          success: item.status === 'success',
        }"
        class="notification"
        v-for="(item, index) in notifications"
        :key="index"
      >
        <div class="brandLogo">
          <img style="width: 50px; border-radius: 50%" :src="item.brandLogo" />
        </div>
        <div class="left">
          <div class="title">
            <div>{{ item.title }}</div>
          </div>
          <div class="message">
            {{ item.content }}
          </div>
        </div>
        <div class="right">
          <div>{{ item.send_time }}</div>
          <div class="img">
            <img :src="item.image_url" />
          </div>
          <div class="platform">
            <div v-if="item.platform === 'android'" class="android">
              <i class="pi pi-android"></i>
            </div>
            <div v-if="item.platform === 'ios'" class="ios">
              <i class="pi pi-apple"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="pagination">
        <div class="prev">
          <a v-if="pagination.start > 1" @click="fetchNotifications('prev')">
            <i class="pi pi-chevron-left"></i>
          </a>
        </div>
        <div class="next">
          <a @click="fetchNotifications('next')">
            <i class="pi pi-chevron-right"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from 'vue';
import useGlobal from '../../composables/useGlobal';

export default {
  setup() {
    const { t, restApi } = useGlobal();

    const notifications = ref();

    const isLoading = ref(false);
    const hasError = ref(false);

    const pagination = reactive({
      start: 1,
      lenght: 10,
    });

    const fetchNotifications = (page) => {
      switch (page) {
        case 'prev':
          pagination.start -= pagination.lenght;
          break;
        case 'next':
          pagination.start += pagination.lenght;
          break;
        default:
          pagination.start = 1;
          break;
      }

      isLoading.value = true;
      restApi
        .getAllNotifications(pagination.start, pagination.lenght)
        .then((res) => {
          notifications.value = res.data.items;
          isLoading.value = false;
        });
    };

    onMounted(() => {
      fetchNotifications();
    });

    return {
      notifications,
      fetchNotifications,
      t,
      isLoading,
      hasError,
      pagination,
    };
  },
};
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  color: gray;
  margin-bottom: 1em;
}

.items {
  display: flex;
  flex-direction: column;
  gap: 1.2em;
}

.notification {
  background-color: #fff;
  border-radius: 10px;
  padding: 1.5em;
  z-index: 1;
  margin: auto;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  display: grid;
  grid-template-columns: auto auto 1fr;
  gap: 0.5em;
  align-items: center;
  width: 100%;
  animation: up 0.5s ease-in-out;
}

.notification .title {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 0.5em;
  width: 100%;
}

.notification .message {
  font-size: 0.9em;
  margin-bottom: 0.5em;
  width: 100%;
}

.notification .img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
}

.btnSet {
  display: flex;
  gap: 0.4em;
}

.platform i {
  color: gray;
}

.pagination {
  display: flex;
  justify-content: space-between;
}

.success {
  border-left: 5px solid #4caf50;
}
</style>
